<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockVideo } from "~/types/contentful";
import type { TypeButton } from "~/types/TypeButton";

const props = defineProps<{
  fields: TypeBlockVideo<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"] & {
    button: TypeButton[];
  };
}>();

const showThumb = ref(true);

const thumbnailUrl = computed(() => {
  return (
    props.fields.videoThumbnail?.fields.file?.url
    ?? (
      props.fields.vimeoVideoId
        ? `https://vumbnail.com/${props.fields.vimeoVideoId}.jpg`
        : ""
    )
  );
});

const handleClick = () => {
  showThumb.value = false;
};
</script>

<template>
  <Container class="block-video py-4 md:py-6">
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <div class="wysiwyg mb-4">
          <RichTextRenderer :document="fields.text" />
        </div>
        <NuxtLink
          v-for="button in fields.button"
          :key="button.title"
          class="btn mr-3"
          :class="'btn--' + button.type"
          :to="button.url"
        >
          {{ button.title }}
        </NuxtLink>
      </div>
      <div class="relative col-span-12 mt-4 lg:col-span-6 lg:mt-0">
        <UiVimeoPlayer
          v-if="!showThumb"
          ref="player"
          class="player"
          :video-url="fields.vimeoVideoId"
          :autoplay="true"
        />

        <div
          v-if="showThumb"
          class="thumb"
          @click="handleClick"
        >
          <NuxtImg
            :src="thumbnailUrl"
            :alt="fields.title"
            :width="975"
            loading="lazy"
            class="aspect-video"
          />
          <div class="play-button">
            <NuxtIcon
              name="play-circle"
              filled
            />
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.block-video {
  :deep(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumb {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgb(0 0 0 / 30%);
    }
  }

  .player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 */
  }

  .play-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    height: 48px;
    width: 48px;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
  }
}
</style>
